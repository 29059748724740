




import { defineComponent, computed } from '@vue/composition-api';
import merge from 'lodash/merge';
import { extractKeyValuePairData, formatInteger, formatDate } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;
    const fields = [
      'JP_ENAME',
      'JP_TNAME',
      'JP_NO',
      'REG_DATE',
      'REG_DATE2',
      'REG_YEAR',
      // 'STATUS_CODE',
      'STATUS_TNAME',
      'PAID_AMT',
      'CAP_AMT',
      'LAST_UPDATE',
      // 'BUSINESS_SIZE_CODE',
    ];
    const propsData = extractKeyValuePairData(fields, apiData);
    propsData.dataSet.PAID_AMT = formatInteger(propsData.dataSet.PAID_AMT);
    propsData.dataSet.CAP_AMT = formatInteger(propsData.dataSet.CAP_AMT);
    propsData.dataSet.REG_DATE = formatDate(propsData.dataSet.REG_DATE);
    propsData.dataSet.REG_DATE2 = formatDate(propsData.dataSet.REG_DATE2);

    Object.assign(propsData, {
      title: 'ข้อมูลทั่วไป',
    });

    return {
      propsData,
    };
  },
});
